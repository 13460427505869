import React from 'react';
import { Smartphone, Repeat, Target, Clock, EyeOff, BarChart } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: <Smartphone className="w-6 h-6 text-indigo-600" />,
      title: "Smart Integration",
      description: "Seamlessly integrate Quran memorization into your daily smartphone usage."
    },
    {
      icon: <Repeat className="w-6 h-6 text-indigo-600" />,
      title: "Focused Repetition",
      description: "Leverage the power of consistent, focused repetition to memorize ayahs effectively."
    },
    {
      icon: <Target className="w-6 h-6 text-indigo-600" />,
      title: "Personalized Goals",
      description: "Set your own memorization goals and track your progress over time."
    },
    {
      icon: <Clock className="w-6 h-6 text-indigo-600" />,
      title: "Time Optimization",
      description: "Make the most of your time by dedicating small portions to memorization daily."
    },
    {
      icon: <EyeOff className="w-6 h-6 text-indigo-600" />,
      title: "Flexible Tracking",
      description: "Set visible and hidden repetition counts to suit your personal preferences."
    },
    {
      icon: <BarChart className="w-6 h-6 text-indigo-600" />,
      title: "Progress Insights",
      description: "Gain valuable insights into your memorization journey and habits."
    }
  ];

  return (
    <section className="py-16 bg-gradient-to-b from-indigo-50 to-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Memorize the Quran, One Ayah at a Time
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            IMAN.app helps you build a consistent habit of Quran memorization, 
            turning your smartphone time into a means of spiritual growth.
          </p>
        </div>
        <div className="mt-16">
          <div className="space-y-10">
            {features.map((feature, index) => (
              <div key={index} className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-12 w-12 rounded-md bg-indigo-100 text-indigo-600">
                    {feature.icon}
                  </span>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">{feature.title}</h3>
                  <p className="mt-1 text-gray-500">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-16 bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Guided by Prophetic Wisdom
            </h3>
            <div className="mt-2 text-sm text-gray-500">
              <p>
                Abu Hurairah narrated that the Messenger of Allah (ﷺ) said:
              </p>
              <blockquote className="mt-2 italic font-medium text-gray-700 border-l-4 border-indigo-500 pl-4 py-2">
                "Take on only as much as you can do of good deeds, for the best of deeds 
                is that which is done consistently, even if it is little."
              </blockquote>
            </div>
          </div>
        </div>
        <div className="mt-10 text-center">
          <a href="#" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
            Start Your Journey
          </a>
        </div>
      </div>
    </section>
  );
};

export default Features;