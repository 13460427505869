import React, { useState } from 'react';
import VerseExplanationModal from './VerseExplanationModal';

const QuranVerseInfoBox = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg shadow-xl p-6 my-8">
        <div className="text-right mb-4">
          <p className="text-2xl font-arabic leading-relaxed">
            وَقَالَ الرَّسُولُ يَا رَبِّ إِنَّ قَوْمِي اتَّخَذُوا هَٰذَا الْقُرْآنَ مَهْجُورًا
          </p>
        </div>
        <div className="mb-4">
          <p className="text-lg italic">
            "And the Messenger will say, 'O my Lord, indeed my people have taken this Qur'an as [a thing] abandoned.'"
          </p>
          <p className="text-sm mt-2">
            - Surah Al-Furqan, Verse 30
          </p>
        </div>
        <button 
          onClick={() => setIsModalOpen(true)}
          className="inline-block bg-white text-indigo-600 font-semibold py-2 px-4 rounded hover:bg-indigo-100 transition duration-300"
        >
          Learn More About This Verse
        </button>
      </div>
      <VerseExplanationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default QuranVerseInfoBox;