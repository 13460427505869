import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Terms of Use</h1>
        
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-xl leading-6 font-bold text-gray-900">IMAN.app Terms of Use</h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Last updated: July 14, 2024</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div id="acceptance" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">1. Acceptance of Terms</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  By accessing or using IMAN.app, you agree to be bound by these Terms of Use and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our Services.
                </dd>
              </div>
              <div id="user-accounts" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">2. User Accounts</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password. You must immediately notify IMAN.app of any unauthorized use of your account.
                </dd>
              </div>
              <div id="intellectual-property" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">3. Intellectual Property</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  The content, features, and functionality of IMAN.app are owned by IMAN.app and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                </dd>
              </div>
              <div id="prohibited-uses" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">4. Prohibited Uses</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  You agree not to use IMAN.app for any unlawful purpose or in any way that could damage, disable, overburden, or impair our servers or networks. Any attempt to gain unauthorized access to any part of our Services is strictly prohibited.
                </dd>
              </div>
              <div id="disclaimer" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">5. Disclaimer of Warranties</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  IMAN.app is provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                </dd>
              </div>
              <div id="limitation-liability" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">6. Limitation of Liability</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  In no event shall IMAN.app be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.
                </dd>
              </div>
              <div id="governing-law" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">7. Governing Law</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  These Terms shall be governed and construed in accordance with the laws of Malaysia, without regard to its conflict of law provisions.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-8 text-center text-sm text-gray-600">
          <p>For any questions about these Terms of Use, please contact us at:</p>
          <p className="font-semibold">legal@iman.app</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;