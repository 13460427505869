import React from 'react';
import QuranVerseInfoBox from './QuranVerseInfoBox';

const OurStoryPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-8">Our Story</h1>
        
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-2xl leading-6 font-medium text-gray-900">The Beginning of IMAN.app</h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Inception</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  In late 2022, a group of Malaysian students in Shiga University were inspired by the engaging nature of language learning apps like Duolingo. They wondered: could a similar approach make Quran memorization more accessible and enjoyable?
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Vision</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  We envisioned an app that would transform Hafazan from a daunting task into an engaging hobby, bringing the beauty and wisdom of the Quran into people's daily lives.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <QuranVerseInfoBox />

        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-2xl leading-6 font-medium text-gray-900">From Concept to Reality</h2>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Development</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  Throughout 2024, our team worked tirelessly to bring IMAN.app to life. We combined proven memorization techniques with modern app design to create an intuitive and effective learning experience.
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Launch</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  In July 2024, IMAN.app was officially launched on both iOS and Android platforms, marking the beginning of our journey to make Hafazan accessible to everyone.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStoryPage;