import React from 'react';

const VerseExplanationModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-2xl leading-6 font-bold text-gray-900">Verse Explanation</h3>
          <p className="text-sm text-gray-500">Surah Al-Furqan, Verse 30</p>
          <div className="mt-2 px-7 py-3">
            <div className="my-3">
              <p className="text-xl text-right font-arabic">وَقَالَ الرَّسُولُ يَا رَبِّ إِنَّ قَوْمِي اتَّخَذُوا هَٰذَا الْقُرْآنَ مَهْجُورًا</p>
            </div>
            <div className="my-3">
              <p className="text-lg font-medium text-gray-900">Translation:</p>
              <p className="text-md text-gray-600">"And the Messenger will say, 'O my Lord, indeed my people have taken this Qur'an as [a thing] abandoned.'"</p>
            </div>
            <div className="my-3">
              <p className="text-lg font-medium text-gray-900">Explanation by Ibn Kathir:</p>
              <p className="text-md text-gray-600">
                Allah informs about His Messenger and Prophet Muhammad (peace be upon him) that he said: "O Lord, indeed my people have taken this Qur'an as a thing abandoned." This refers to the polytheists who did not listen to the Qur'an or pay attention to it. As Allah says, "And those who disbelieve say, 'Do not listen to this Qur'an and speak noisily during [the recitation of] it that perhaps you will overcome.'" [Fussilat: 26]
              </p>
              <p className="text-md text-gray-600 mt-2">
                When the Qur'an was recited to them, they would increase their idle talk and speak about other things so that they would not hear it. This is one form of abandoning it. Other forms include:
              </p>
              <ul className="list-disc list-inside text-md text-gray-600 mt-2">
                <li>Neglecting to learn and memorize it</li>
                <li>Not believing in it or affirming its truth</li>
                <li>Not pondering over its meanings</li>
                <li>Not acting upon it, following its commands, and avoiding its prohibitions</li>
                <li>Turning away from it towards poetry, speech, singing, amusement, or any other way that is not from the Qur'an</li>
              </ul>
              <p className="text-md text-gray-600 mt-2">
                We ask Allah, the Generous and Bestower, Who is capable of all things, to save us from what displeases Him and to use us in what pleases Him - in memorizing His Book, understanding it, and establishing its injunctions night and day, in the way that He loves and is pleased with. Indeed, He is Generous and Giving.
              </p>
            </div>
            <div className="my-3 bg-indigo-100 p-4 rounded-md">
              <p className="text-lg font-medium text-gray-900">Simplified Explanation:</p>
              <p className="text-md text-gray-700">
                This verse highlights the Prophet Muhammad's concern that people were neglecting the Qur'an. Ibn Kathir explains that "abandoning" the Qur'an can take many forms, not just ignoring it completely. It includes not listening to it attentively, not trying to understand its meanings, not believing in it, not memorizing it, and not applying its teachings in daily life. The lesson here is that the Qur'an should be an active part of a Muslim's life - it should be read, understood, believed, and acted upon. This verse serves as a reminder for Muslims to engage with the Qur'an regularly and meaningfully.
              </p>
            </div>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerseExplanationModal;