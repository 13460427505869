import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Clock, Mail, HelpCircle } from 'lucide-react';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    country: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch user's IP address and country
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setIpAddress(data.ip);
        setFormData(prevData => ({ ...prevData, country: data.country_name }));
      })
      .catch(error => console.error('Error fetching IP and country:', error));

    // Fetch list of countries
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => {
        const sortedCountries = data
          .map(country => country.name.common)
          .sort((a, b) => a.localeCompare(b));
        setCountries(sortedCountries);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');
    setMessageType('');

    const sheetsUrl = 'https://script.google.com/macros/s/AKfycby3LFS2tHdZExGVSq0Cm0xxIpXk0sizKHqw99hf6XdtBOHu8cb0TbyHhCVHomhyX1gxfA/exec';

    try {
      const response = await fetch(sheetsUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          ipAddress: ipAddress,
          userAgent: navigator.userAgent
        }),
      });

      setMessage('Thank you for your message. We\'ll get back to you soon!');
      setMessageType('success');
      setFormData({ name: '', email: '', subject: '', message: '', country: formData.country });
    } catch (error) {
      console.error('Error details:', error);
      setMessage('An error occurred. Please try again or contact us directly.');
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <section className="bg-gradient-to-r from-indigo-500 to-purple-600 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <motion.h2 
          className="text-3xl font-extrabold text-white sm:text-4xl text-center mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Contact Support
        </motion.h2>

        {/* Contact Information and Notes */}
        <motion.div
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Before You Contact Us</h3>
          <div className="space-y-4">
            <div className="flex items-start">
              <HelpCircle className="w-6 h-6 text-indigo-500 mr-2 flex-shrink-0" />
              <p className="text-gray-600">
                Please contact IMAN.app support for:
                <ul className="list-disc pl-5 mt-2">
                  <li>Technical issues with the app</li>
                  <li>Account-related problems</li>
                  <li>Suggestions for app improvements</li>
                  <li>Questions about Hafazan techniques</li>
                  <li>Collaboration or partnership inquiries</li>
                </ul>
              </p>
            </div>
            <div className="flex items-center">
              <Clock className="w-6 h-6 text-indigo-500 mr-2 flex-shrink-0" />
              <p className="text-gray-600">
                Our working hours are Monday to Friday, 9:00 AM - 5:00 PM (GMT +9)
              </p>
            </div>
            <div className="flex items-center">
              <Mail className="w-6 h-6 text-indigo-500 mr-2 flex-shrink-0" />
              <p className="text-gray-600">
                For urgent matters outside working hours, please email: urgent@iman.app
              </p>
            </div>
          </div>
        </motion.div>

        {/* Support Form */}
        <motion.form 
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="country">
              Country
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            >
              <option value="">Select a country</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
              Subject
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="subject"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </motion.form>
        
        {message && (
          <motion.p 
            className={`text-center text-sm ${messageType === 'success' ? 'text-green-300' : 'text-red-300'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {message}
          </motion.p>
        )}
      </div>
    </section>
  );
};

export default SupportForm;