import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import LandingPage from './LandingPage';
import SimulatorPage from './SimulatorPage';
import OurStoryPage from './OurStory';
import Footer from './Footer';
import SupportForm from './SupportForm';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';

const App = () => {
  return (
    <Router>
      <div className="font-sans text-gray-900 bg-gray-100 min-h-screen">
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/simulator" element={<SimulatorPage />} />
          <Route path="/support" element={<SupportForm />} />
          <Route path="/our-story" element={<OurStoryPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
          <Route path="/terms-of-use" element={<TermsOfUse />}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;