import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Privacy Policy</h1>
        
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-xl leading-6 font-bold text-gray-900">IMAN.app Privacy Policy</h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Last updated: July 14, 2024</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div id="introduction" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">1. Introduction</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  IMAN.app ("we," "our," or "us") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your information when you use our mobile application and services (collectively, the "Services").
                </dd>
              </div>
              <div id="information-collection" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">2. Information We Collect</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  We collect information that you provide directly to us, such as when you create an account, use our Services, or communicate with us. This may include your name, email address, and usage data related to your Quran memorization progress. We also automatically collect certain information about your device and how you interact with our Services.
                </dd>
              </div>
              <div id="information-use" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">3. Use of Information</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  We use the information we collect to provide, maintain, and improve our Services, to communicate with you, and to comply with legal obligations. We do not sell your personal information to third parties.
                </dd>
              </div>
              <div id="data-security" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">4. Data Security</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </dd>
              </div>
              <div id="user-rights" className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">5. Your Rights</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  You have the right to access, correct, or delete your personal information. You may also have additional rights depending on your jurisdiction. To exercise these rights, please contact us using the information provided at the end of this policy.
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="mt-8 text-center text-sm text-gray-600">
          <p>For any questions about this Privacy Policy, please contact us at:</p>
          <p className="font-semibold">privacy@iman.app</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;