import React from 'react';

const Footer = () => {
  const footerLinks = [
    {
      title: 'Learn and Memorize',
      items: ['Getting Started', 'Memorization Techniques', 'Daily Exercises', 'Progress Tracking']
    },
    {
      title: 'IMAN App',
      items: ['About Us', 'Our Mission', 'Blog', 'Careers']
    },
    {
      title: 'Community',
      items: ['Study Groups', 'Forums', 'Events', 'Testimonials']
    },
    {
      title: 'Support',
      items: ['FAQ', 'Contact Us', 'Tutorials', 'Feedback']
    }
  ];

  return (
    <footer className="bg-indigo-50 text-indigo-800 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {footerLinks.map((column, index) => (
            <div key={index}>
              <h3 className="font-semibold text-sm mb-4">{column.title}</h3>
              <ul>
                {column.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="mb-2">
                    <a href="#" className="text-sm hover:underline">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-8 pt-8 border-t border-indigo-200">
          <p className="text-xs mb-4">
            Join our community: <a href="#" className="text-indigo-600 hover:underline">Find a local study group</a> or <a href="#" className="text-indigo-600 hover:underline">start your own</a>. Need help? Call 1-800-IMAN-APP.
          </p>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-xs mb-4 md:mb-0">
              Copyright © {new Date().getFullYear()} IMAN App. All rights reserved.
            </p>
            <div className="flex flex-wrap justify-center md:justify-end space-x-4">
              <a href="/privacy-policy" className="text-xs hover:underline">Privacy Policy</a>
              <a href="/terms-of-use" className="text-xs hover:underline">Terms of Use</a>
              <a href="#" className="text-xs hover:underline">Community Guidelines</a>
              <a href="#" className="text-xs hover:underline">Accessibility</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;