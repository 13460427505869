import React, { useState, useEffect } from 'react';
import { Slider } from './components/ui/slider';
import { Switch } from './components/ui/switch';
import { Button } from './components/ui/button';
import { Calendar, ChevronRight, Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./components/ui/tooltip"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./components/ui/dialog"

const QuranMemorizationSimulator = () => {
  const [useAgeInput, setUseAgeInput] = useState(true);
  const [age, setAge] = useState(30);
  const [birthDate, setBirthDate] = useState('');
  const [ayahsPerDay, setAyahsPerDay] = useState(1);
  const [memorizedJuz, setMemorizedJuz] = useState([]);
  const [result, setResult] = useState('');
  const [isExplanationOpen, setIsExplanationOpen] = useState(false);

  const juzCount = 30;

  useEffect(() => {
    calculateResult();
  }, [age, birthDate, ayahsPerDay, memorizedJuz]);

  const calculateResult = () => {
    const totalAyahs = 6236;
    const memorizedAyahs = memorizedJuz.length * (totalAyahs / juzCount);
    const remainingAyahs = totalAyahs - memorizedAyahs;
    const daysToComplete = Math.ceil(remainingAyahs / ayahsPerDay);

    let currentAge = age;
    if (!useAgeInput && birthDate) {
      const birthDateObj = new Date(birthDate);
      const today = new Date();
      currentAge = today.getFullYear() - birthDateObj.getFullYear();
    }

    const yearsToComplete = Math.floor(daysToComplete / 365);
    const monthsToComplete = Math.floor((daysToComplete % 365) / 30);
    const remainingDays = daysToComplete % 30;

    const completionAge = currentAge + yearsToComplete + (monthsToComplete / 12) + (remainingDays / 365);

    setResult(`Estimated time: ${yearsToComplete}y ${monthsToComplete}m ${remainingDays}d
Estimated age at completion: ${Math.round(completionAge)}`);
  };

  const toggleJuz = (juzNumber) => {
    setMemorizedJuz(prev => 
      prev.includes(juzNumber)
        ? prev.filter(num => num !== juzNumber)
        : [...prev, juzNumber]
    );
  };

  return (
    <div className="font-sans text-gray-900 bg-white shadow-sm rounded-lg overflow-hidden max-w-md mx-auto">
      <div className="px-4 py-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          <span className="block">Quran Memorization</span>
          <span className="block text-indigo-600">Simulator</span>
        </h2>
        
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-700">Input:</span>
            <div className="flex items-center space-x-2">
              <span className="text-xs text-gray-500">Age</span>
              <Switch
                checked={!useAgeInput}
                onCheckedChange={() => setUseAgeInput(!useAgeInput)}
              />
              <span className="text-xs text-gray-500">Birth Date</span>
            </div>
          </div>

          {useAgeInput ? (
            <div className="space-y-1">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700">Current Age: {age}</span>
              </div>
              <Slider
                value={[age]}
                onValueChange={(value) => setAge(value[0])}
                max={100}
                step={1}
              />
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <input
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                className="flex-grow px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon" className="h-8 w-8">
                      <Calendar className="text-gray-400" size={16} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Click to open calendar</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}

          <div className="space-y-1">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-700">Ayahs per day: {ayahsPerDay}</span>
            </div>
            <Slider
              value={[ayahsPerDay]}
              onValueChange={(value) => setAyahsPerDay(value[0])}
              max={10}
              step={1}
            />
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-2">Select Memorized Juz</h3>
            <div className="grid grid-cols-6 gap-1">
              {[...Array(juzCount)].map((_, index) => (
                <Button
                  key={index}
                  variant={memorizedJuz.includes(index + 1) ? "default" : "outline"}
                  size="sm"
                  onClick={() => toggleJuz(index + 1)}
                  className={`w-full p-1 text-xs ${memorizedJuz.includes(index + 1) ? 'bg-indigo-600 text-white hover:bg-indigo-700' : 'text-gray-700 hover:bg-gray-50'}`}
                >
                  {index + 1}
                </Button>
              ))}
            </div>
            <Dialog open={isExplanationOpen} onOpenChange={setIsExplanationOpen}>
              <DialogTrigger asChild>
                <Button variant="link" className="mt-2 text-xs text-indigo-600 hover:text-indigo-800">
                  <Info className="w-4 h-4 mr-1" />
                  How is this calculated?
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Memorized Juz Calculation</DialogTitle>
                  <DialogDescription>
                    <p className="mt-2">
                      The Quran is divided into 30 juz (parts), and each juz contains approximately 1/30th of the total verses (ayahs).
                    </p>
                    <p className="mt-2">
                      When you select a juz as memorized, we consider it fully memorized and add it to your progress.
                    </p>
                    <p className="mt-2">
                      The total number of ayahs in the Quran is 6,236. So, each juz is estimated to contain about 208 ayahs (6,236 ÷ 30).
                    </p>
                    <p className="mt-2">
                      Your progress is calculated as: (Number of memorized juz × 208) ÷ 6,236
                    </p>
                    <p className="mt-2">
                      This gives us an approximation of your memorization progress, which we then use to estimate the time remaining to complete your memorization journey.
                    </p>
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </div>

          {result && (
            <div className="mt-4 p-3 bg-indigo-50 rounded-md">
              <p className="text-indigo-700 text-sm font-medium whitespace-pre-line">{result}</p>
            </div>
          )}

          <div className="mt-4">
            <Button className="w-full bg-indigo-600 text-white hover:bg-indigo-700 flex items-center justify-center text-sm py-2">
              Start Hafazan Journey
              <ChevronRight className="ml-1 h-4 w-4" aria-hidden="true" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuranMemorizationSimulator;