import React from 'react';
import { BookOpen, Users, Repeat } from 'lucide-react';

const HafazanExplanation = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-indigo-50 via-white to-indigo-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Understanding <span className="text-indigo-600">Hafazan</span>
          </h2>
          <p className="mt-4 text-xl text-gray-500">
            Cultivating a Culture of Quranic Memorization
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">Etymology of Hafazan</h3>
              <p className="text-gray-600">
                The term "Hafazan" (حَفَظَان) comes from the Arabic root حفظ (ḥ-f-ẓ), which means "to guard," "to protect," or "to preserve." In the context of the Quran, it refers to the act of memorizing and preserving the holy text in one's heart and mind.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">IMAN.app's Mission</h3>
              <p className="text-gray-600">
                IMAN.app aims to normalize Hafazan as a widespread cultural practice in the Muslim world by making Quranic memorization accessible, engaging, and integrated into daily life.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">How IMAN.app Cultivates Hafazan Culture</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <BookOpen className="w-6 h-6 text-indigo-600 mr-2 flex-shrink-0" />
                <span>Integrating memorization into daily smartphone usage, making it a natural part of modern life</span>
              </li>
              <li className="flex items-start">
                <Users className="w-6 h-6 text-indigo-600 mr-2 flex-shrink-0" />
                <span>Building a community of learners who support and motivate each other</span>
              </li>
              <li className="flex items-start">
                <Repeat className="w-6 h-6 text-indigo-600 mr-2 flex-shrink-0" />
                <span>Encouraging consistent, bite-sized practice aligned with Islamic teachings on steady, sustainable good deeds</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-12 text-center">
          <p className="text-lg text-gray-600 italic">
            "The one who was devoted to the Qur'an will be told on the Day of Resurrection: 'Recite and ascend (in ranks) as you used to recite when you were in the world. Your rank will be at the last verse you recite.'"
          </p>
          <p className="mt-2 text-sm text-gray-500">- Sunan Abu Dawud 1464</p>
        </div>
      </div>
    </section>
  );
};

export default HafazanExplanation;