import React, { useState } from 'react';
import { motion } from 'framer-motion';

const EmailCollection = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');
    setMessageType('');

    // Replace with your actual Google Sheets Web App URL
    const sheetsUrl = 'https://script.google.com/macros/s/AKfycbxkwbkljJQXPziNWBTSGXyvLn6Kw5FjXiZ9xUJJIwfSnxF-O_aKafke-_F-KOZX_rYe/exec';

    try {
      const response = await fetch(sheetsUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      // Since 'no-cors' mode doesn't allow reading the response,
      // we assume success if no error is thrown
      setMessage('Terima kasih! We\'ll keep you updated on IMAN.app');
      setMessageType('success');
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      setMessage('Oops! Something went wrong. Please try again.');
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="bg-gradient-to-r from-indigo-500 to-purple-600 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto text-center">
        <motion.h2 
          className="text-3xl font-extrabold text-white sm:text-4xl"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Join the IMAN.app Community
        </motion.h2>
        <motion.p 
          className="mt-4 text-lg leading-6 text-indigo-200"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Be the first to know about updates, tips, and exclusive offers!
        </motion.p>
        <motion.form 
          onSubmit={handleSubmit} 
          className="mt-8 flex flex-col sm:flex-row justify-center items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <label htmlFor="email" className="sr-only">Email address</label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="w-full sm:w-64 px-5 py-3 mb-3 sm:mb-0 placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white focus:border-white rounded-md"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="w-full sm:w-auto sm:ml-3">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full sm:w-auto flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Notify Me'}
            </motion.button>
          </div>
        </motion.form>
        {message && (
          <motion.p 
            className={`mt-3 text-sm ${messageType === 'success' ? 'text-green-300' : 'text-red-300'}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {message}
          </motion.p>
        )}
      </div>
      {/* ... (social media links remain unchanged) ... */}
    </section>
  );
};

export default EmailCollection;